import humanizeDuration from "humanize-duration";
import { DateTime } from "luxon";
export var DEFAULT_LANGUAGE = "en";
/**
 * Util to extract 2 letter langauge code from regional languages, returns same string if not regionalized
 * e.g. `pt-BR` -> `pt`
 */ export var languageWithoutRegion = function(languageWithMaybeRegion) {
    return languageWithMaybeRegion.split("-")[0];
};
/**
 * Wrapper function to standardize the way we humanize durations
 * Defaults to and falls back to DEFAULT_LANGUAGE
 * @param durationMillis
 * @param language
 */ export var humanizeDurationRounded = function(durationMillis) {
    var language = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : DEFAULT_LANGUAGE;
    try {
        // throws if language is unknown
        return humanizeDuration(durationMillis, {
            units: [
                "y",
                "mo"
            ],
            round: true,
            language: languageWithoutRegion(language)
        });
    } catch (e) {
        return humanizeDuration(durationMillis, {
            units: [
                "y",
                "mo"
            ],
            round: true,
            language: DEFAULT_LANGUAGE
        });
    }
};
/**
 * Wrapper function to standardize the way we display date time
 * Defaults to and falls back to DEFAULT_LANGUAGE
 * @param date
 * @param language
 *
 * Returns empty string if date is undefined
 */ export var localeDateTimeString = function(date) {
    var language = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : DEFAULT_LANGUAGE;
    if (!date) {
        return "";
    }
    try {
        return date.setLocale(languageWithoutRegion(language)).toLocaleString({
            year: "numeric",
            month: "long",
            day: "numeric"
        });
    } catch (e) {
        return date.setLocale(DEFAULT_LANGUAGE).toLocaleString({
            year: "numeric",
            month: "long",
            day: "numeric"
        });
    }
};
/**
 * Returns the symbol based on the currency code if a symbol exists
 * otherwise returns undefined
 * Defaults to and falls back to DEFAULT_LANGUAGE
 *
 * @param currencyCode string (e.g: "EUR", "USD")
 */ export var getCurrencySymbol = function(currencyCode) {
    var language = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : DEFAULT_LANGUAGE;
    if (!currencyCode) {
        return undefined;
    }
    var opts = {
        style: "currency",
        currency: currencyCode
    };
    var numberFormat;
    try {
        numberFormat = new Intl.NumberFormat(languageWithoutRegion(language), opts);
    } catch (error) {
        numberFormat = new Intl.NumberFormat(DEFAULT_LANGUAGE, opts);
    }
    var symbol = numberFormat.formatToParts(undefined).find(function(x) {
        return x.type === "currency";
    });
    return symbol ? symbol.value : undefined;
};
var SI_SYMBOL = [
    "",
    "k",
    "M",
    "G",
    "T",
    "P",
    "E"
];
/**
 * Abrreviates numbers using SI symbols. E.g 10_000 => 10K
 *
 * @param value the value to abbreviate
 * @param toFixed the amount of digits to fix to
 */ export var abbreviateNumber = function(value) {
    var toFixed = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(value)) / 3 | 0;
    // if zero, we don't need a suffix
    if (tier == 0) return value;
    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);
    // scale the number
    var scaled = value / scale;
    // format number and add suffix
    return scaled.toFixed(toFixed) + suffix;
};
/**
 *
 * Usage:
 *
 * const array: (string | null)[] = ["foo", "bar", null, "zoo", null];
 * const filteredArray: string[] = array.filter(notEmpty);
 *
 * @param value nullable 'value
 */ export var notEmpty = function(value) {
    return value !== null && value !== undefined;
};
/**
 * Convert a DB period to Luxon DateTimes and a Duration
 *
 * @param period A Period coming from the database ("DateRange")
 */ export var periodToLuxon = function(period) {
    if (!period || !period.start || !period.end) throw Error("Periods should have a start and end, they are NOT NULL in DB");
    var start = DateTime.fromISO(period.start.value);
    var end = DateTimeFromPeriodDate(period.end.value);
    return {
        startDate: start,
        endDate: end,
        duration: end === "infinity" ? "infinity" : end.diff(start)
    };
};
var DateTimeFromPeriodDate = function(val) {
    if (val === "infinity") return "infinity";
    return DateTime.fromISO(val);
};
export function isCurrentPeriod(period) {
    if (!period) return false;
    var today = new Date().toISOString().split("T")[0];
    return !!(period.start && period.end && period.start.value <= today && (period.end.value >= today || period.end.value === "infinity"));
}
