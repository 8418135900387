import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import produce from "immer";
import { DateTime, Duration } from "luxon";
import { useCallback, useEffect } from "react";
import { usePerson } from "@aletheia/common/components/PersonProvider";
import { useLocalStorage } from "@aletheia/common/utils/hooks";
import { PledgeType, useGetIncomesByPersonIdQueryWithReducer, useGetPledgesByPersonIdQueryWithReducer } from "@aletheia/graphql";
import { DEFAULT_PLEDGE_DURATION, DEFAULT_PLEDGE_PERCENTAGE, DEFAULT_TRIAL_PLEDGE_DURATION } from "../constants";
var updateState = function(param) {
    var state = param.state, updatedIncome = param.updatedIncome, updatedWealth = param.updatedWealth, updatedPledge = param.updatedPledge, updatedPerson = param.updatedPerson;
    return produce(state, function(draft) {
        if (updatedPledge) {
            draft.pledge = _object_spread({}, draft.pledge, updatedPledge);
            // Updated pledge to defaults when switching PledgeType
            if (updatedPledge.pledgeType === PledgeType.GivingWhatWeCan) {
                draft.pledge.duration = DEFAULT_PLEDGE_DURATION;
                draft.pledge.incomePercentage = DEFAULT_PLEDGE_PERCENTAGE;
            } else if (updatedPledge.pledgeType === PledgeType.TryGiving) {
                draft.pledge.duration = DEFAULT_TRIAL_PLEDGE_DURATION;
            }
        }
        if (updatedIncome) {
            draft.income = _object_spread({}, draft.income, updatedIncome);
        }
        if (updatedWealth) {
            draft.wealth = _object_spread({}, draft.wealth, updatedWealth);
        }
        if (updatedPerson) {
            draft.person = _object_spread({}, draft.person, updatedPerson);
        }
    });
};
export var useSignUpData = function(initialState) {
    var ref, ref1;
    // External Data
    var Person = usePerson().Person;
    var ref2 = _sliced_to_array(useGetPledgesByPersonIdQueryWithReducer(), 1), Pledges = ref2[0];
    var ref3 = _sliced_to_array(useGetIncomesByPersonIdQueryWithReducer(), 1), Incomes = ref3[0];
    // Get active Pledge
    var ActivePledge = Pledges === null || Pledges === void 0 ? void 0 : Pledges.filter(function(p) {
        return p.period.end && (p.period.end.value === "infinity" || DateTime.fromISO(p.period.end.value) > DateTime.now());
    })[0];
    // Get latest Income
    var Income = (Incomes === null || Incomes === void 0 ? void 0 : Incomes.length) ? Incomes.sort(function(a, b) {
        return b.id.localeCompare(a.id);
    })[0] : undefined;
    // Internal State
    var ref4 = _sliced_to_array(useLocalStorage("PledgeForm", initialState, {
        // Convert ISO values into Luxon objects after loading from local storage
        convertBeforeLoad: function(values) {
            var startDate = values.pledge.startDate && DateTime.fromISO(values.pledge.startDate);
            var duration = values.pledge.duration.toLowerCase() === "infinity" ? "infinity" : values.pledge.duration && Duration.fromISO(values.pledge.duration);
            var birthDate = values.person.birthDate && DateTime.fromISO(values.person.birthDate);
            return _object_spread_props(_object_spread({}, values), {
                pledge: _object_spread_props(_object_spread({}, values.pledge), {
                    id: undefined,
                    startDate: startDate,
                    duration: duration
                }),
                person: _object_spread_props(_object_spread({}, values.person), {
                    birthDate: birthDate
                })
            });
        },
        expirationTime: 24 * 60 * 60 * 1000
    }), 2), state = ref4[0], setState = ref4[1];
    // Sync internal state with existing PersonProvider and if the
    // user has not already filled out the signup person fields
    useEffect(function() {
        if (Person && state.person && !state.person.firstName && Person.firstName && !state.person.lastName && Person.lastName && !state.person.birthDate && Person.birthDate && !state.person.email && Person.email) {
            var firstName = Person.firstName, lastName = Person.lastName, birthDate = Person.birthDate, email = Person.email;
            updatePerson({
                // Make sure that if no firstName/ lastName is set we set it to an empty string in order to be considered invalid
                firstName: firstName || "",
                lastName: lastName || "",
                birthDate: birthDate ? DateTime.fromISO(birthDate) : undefined,
                email: email || ""
            });
        }
    }, [
        Person
    ]);
    // Sync internal state with existing Income and if the
    // user has not already filled out the signup income fields
    useEffect(function() {
        if (Income && state.income && !state.income.amount && Income.amount) {
            var id = Income.id, amount = Income.amount;
            updateIncome({
                id: id,
                amount: amount
            });
        }
    }, [
        Income
    ]);
    var updatePledge = useCallback(function(pledge) {
        setState(function(state) {
            return updateState({
                state: state,
                updatedPledge: pledge
            });
        });
    }, [
        setState
    ]);
    var updateIncome = useCallback(function(income) {
        setState(function(state) {
            return updateState({
                state: state,
                updatedIncome: income
            });
        });
    }, [
        setState
    ]);
    var updateWealth = useCallback(function(wealth) {
        setState(function(state) {
            return updateState({
                state: state,
                updatedWealth: wealth
            });
        });
    }, [
        setState
    ]);
    var updatePerson = useCallback(function(person) {
        setState(function(state) {
            return updateState({
                state: state,
                updatedPerson: person
            });
        });
    }, [
        setState
    ]);
    return {
        state: state,
        updatePledge: updatePledge,
        updateIncome: updateIncome,
        updateWealth: updateWealth,
        updatePerson: updatePerson,
        hasActivePledge: !!ActivePledge,
        activePledgeEndDate: ActivePledge === null || ActivePledge === void 0 ? void 0 : (ref = ActivePledge.period) === null || ref === void 0 ? void 0 : (ref1 = ref.end) === null || ref1 === void 0 ? void 0 : ref1.value
    };
};
