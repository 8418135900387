import React, { useEffect, useState } from "react";
export var useWindowSize = function() {
    var ref = useState({
        width: 0,
        height: 0
    }), windowSize = ref[0], setWindowSize = ref[1];
    useEffect(function() {
        var handler = function() {
            setWindowSize({
                // screen is more robust on mobile devices, get whichever is reporting smaller
                width: Math.min(window.innerWidth, screen.availWidth),
                height: Math.min(window.innerHeight, screen.availHeight)
            });
        };
        // Set size at the first client-side load
        handler();
        window.addEventListener("resize", handler);
        // Remove event listener on cleanup
        return function() {
            window.removeEventListener("resize", handler);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return windowSize;
};
