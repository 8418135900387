import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import { DateTime, Duration } from "luxon";
import { z } from "zod";
// Ensure types generated by Zod match the declared types (e.g. of graphql)
export var expectType = function(_) {
/* noop: function just ensures that type in equals the generic */ };
// Alias the Luxon types to Zod compatible types
export var DateTimeZ = z.any().refine(function(x) {
    return _instanceof(x, DateTime);
});
export var DurationZ = z.any().refine(function(x) {
    return _instanceof(x, Duration);
});
